import { INLINES } from '@contentful/rich-text-types';
import { Metadata, EntrySys } from 'contentful';
import * as moment from 'moment';

import { State, ElectionInfo } from '../data';
import { ParsedNote } from '../services';

import {
  ActiveLocale,
  ActiveLocalizedBoolean,
  ActiveLocalizedRichTextObj,
  ActiveLocalizedString,
  ActiveLocalizedUrl,
  ActiveLocalizedUrlArray,
} from '../utils/localization';
import { Option } from '../utils/option';

// MODELS USED IN IWV

// BEGIN TERRITORY MODELS IN IWV
export type TerritoryConfig = {
  territoryName: Option<string>;
  territoryCode: Option<string>;
  vepConfig: Option<JurisdictionVepConfig>;
};

// END TERRITORY MODELS IN IWV

// BEGIN SITEWIDE MODELS IN IWV
export type SitewideAlertsObject = {
  sitewideAlerts?: Array<LinkedAlertFields>;
};
//END SITEWIDE MODELS IN IWV

// BEGIN JURISDICTION MODELS IN IWV
export type OnlineButtonLink = {
  url: ActiveLocalizedUrl;
  linkText: ActiveLocalizedString;
};

export type JurisdictionConfig = {
  stateName: Option<string>;
  stateCode: Option<string>;
  voterHotline: Option<string>;
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
  supportedLocales: JurisdictionLocaleSupport;
  priorityLocales: JurisdictionLocaleSupport;
  vepConfig: JurisdictionVepConfig;
  pollingLocationLookupConfig: JurisdictionPollingLookupConfig;
  registrationConfig: Option<JurisdictionRegistrationConfig>;
  jurisdictionAlert?: JurisdictionAlert;
  electionInfo?: ElectionInfo;
  ballotRequestConfig: Option<JurisdictionBallotRequestConfig>;
  updatedAt: string;
};

export type JurisdictionLocaleSupport = Array<ActiveLocale>;

/* 
About `ImportantDates`:
- The only field guaranteed to exist is earlyVotingStartIsSameStatewide.
- While these date types are available, 
  they are not universally relevant across jurisdictions.
- The Contentful model combines the dateBy and dateByNote fields.
- The `ParsedNote` type represents either the (short) Note or Long Note fields,
  as ActiveLocalizedString: (short) Note, or ContentfulRichTextObject: Long Note.
*/
export type ImportantDates = {
  earlyVotingStartBy?: moment.Moment;
  earlyVotingStartByNote?: ParsedNote;
  earlyVotingStartIsSameStatewide: Option<boolean>;
  electionDay?: moment.Moment;
  electionDayStatewideHours?: ParsedNote;
  ballotRequestBy?: moment.Moment;
  ballotRequestByNote?: ParsedNote;
  ballotDropoffBy?: moment.Moment;
  ballotDropoffByNote?: ParsedNote;
  ballotPostmarkBy?: moment.Moment;
  ballotPostmarkByNote?: ParsedNote;
  ballotReceiveBy?: moment.Moment;
  ballotReceiveByNote?: ParsedNote;
  inPersonAbsenteeStartBy?: moment.Moment;
  inPersonAbsenteeStartByNote?: ParsedNote;
  registerOnlineBy?: moment.Moment;
  registerOnlineByNote?: ParsedNote;
  registerInPersonBy?: moment.Moment;
  registerInPersonByNote?: ParsedNote;
  registerSameDayNote?: ParsedNote;
  registerReceiveBy?: moment.Moment;
  registerReceiveByNote?: ParsedNote;
  registerPostmarkBy?: moment.Moment;
  registerPostmarkByNote?: ParsedNote;
  voteByMailNote?: ContentfulRichTextObject;
};

export type LocationLookupExperienceTypes =
  | 'SOS website'
  | 'IWV Locate tool'
  | 'none';

export type JurisdictionPollingLookupConfig = {
  displayVotingLocationLookup: boolean;
  lookupExperience: LocationLookupExperienceTypes;
  sosLookupUrl: ActiveLocalizedUrl | null;
  locateMessage: JurisdictionLocateMessage | null;
  earlyAbsenteeExcuse: {
    copy: ContentfulRichTextObject | null;
  } | null;
  informationalNotes: {
    dropoffLocations: ContentfulRichTextObject | null;
    earlyVoteLocations: ContentfulRichTextObject | null;
    electionDayLocations: ContentfulRichTextObject | null;
  } | null;
};

export type JurisdictionLocateMessage = {
  headline: ActiveLocalizedString;
  errorMessage: ContentfulRichTextObject;
  startDate: ContentfulDate;
  endDate: ContentfulDate;
};

export type JurisdictionRegistrationConfig = {
  registrationFlowEnabled: boolean;
  displayRegistrationLookup: boolean;
  sameDay: {
    enabled: boolean;
    copy?: ContentfulRichTextObject;
    sameDayHeading?: ActiveLocalizedString;
  };
  online: {
    enabled: boolean;
    displayDeadline: boolean;
    copy?: ContentfulRichTextObject;
    onlineUrl?: ActiveLocalizedUrl;
    onlineButtonText?: ActiveLocalizedString;
    onlineHeading?: ActiveLocalizedString;
    alternateUrl?: ActiveLocalizedUrl;
    alternateButtonText?: ActiveLocalizedString;
  };
  mail: {
    enabled: boolean;
    displayDeadline: boolean;
    showRegistrationSteps: boolean;
    copy?: ContentfulRichTextObject;
    mailUrl?: ActiveLocalizedUrl;
    mailButtonText?: ActiveLocalizedString;
    mailHeading?: ActiveLocalizedString;
  };
  inPerson: {
    enabled: boolean;
    displayDeadline: boolean;
    copy?: ContentfulRichTextObject;
    inPersonUrl?: ActiveLocalizedUrl;
    inPersonButtonText?: ActiveLocalizedString;
    inPersonHeading?: ActiveLocalizedString;
  };
};

export type JurisdictionVepConfig = {
  jurisdictionCode: string;
  vepEnabled: boolean;
  locationLookup: {
    displayOnVep: boolean;
    displayInDatesDeadlines: boolean;
  };
  importantDatesAndDeadlines: ImportantDates | null;
  idRequirements: {
    copy: ContentfulRichTextObject;
  } | null;
  registrationRequirements: {
    copy: ContentfulRichTextObject;
  } | null;
  howToCompleteBallot: {
    copy: ContentfulRichTextObject;
  } | null;
  customSection: {
    heading: ActiveLocalizedString;
    copy: ContentfulRichTextObject;
  } | null;
};

// fallback/default 'everything is off' vep config
export const FALLBACK_VEP_CONFIG_OBJECT: JurisdictionVepConfig = {
  jurisdictionCode: 'unknown',
  vepEnabled: false,
  locationLookup: {
    displayOnVep: false,
    displayInDatesDeadlines: false,
  },
  importantDatesAndDeadlines: null,
  idRequirements: null,
  registrationRequirements: null,
  howToCompleteBallot: null,
  customSection: null,
};

export type JurisdictionAlert = Array<LinkedAlertFields>;

export type BallotRequestExperienceTypes =
  | 'SOS website'
  | 'contact capture form' // removed from Contentful; unused in IWV
  | 'none';

export type JurisdictionBallotRequestConfig = {
  hasUniversalVBM: boolean;
  ballotRequestFlowEnabled: boolean;
  ballotRequestExperience: BallotRequestExperienceTypes;
  sosBallotRequestUrl: ActiveLocalizedUrl;
  ballotRequestButtonText: ActiveLocalizedString;
};

export type JurisdictionHotlineFooterOverride = {
  hotlineOverrideCopy: {
    copy: ContentfulRichTextObject;
  } | null;
  footerOverrideCopy: {
    copy: ContentfulRichTextObject;
  } | null;
  stateCode: string;
};

// BEGIN JURISDICTION: URL TYPES
export type ExternalContentfulUrls = {
  onlineReg: ActiveLocalizedUrl | undefined;
  alternateReg: ActiveLocalizedUrl | undefined;
  mailInReg: ActiveLocalizedUrl | undefined;
  inPersonReg: ActiveLocalizedUrl | undefined;
  ballotRequest: ActiveLocalizedUrl | undefined;
  locationLookup: ActiveLocalizedUrl | undefined;
  vepVBMRichText?: ActiveLocalizedUrlArray;
  vepRegRichText?: ActiveLocalizedUrlArray;
  vepIdRichText?: ActiveLocalizedUrlArray;
  vepCompleteBallotRichText?: ActiveLocalizedUrlArray;
  locateRichText?: ActiveLocalizedUrlArray;
  alertRichText?: ActiveLocalizedUrlArray;
  absenteeExcuseRichText?: ActiveLocalizedUrlArray;
  dropoffInformationalRichText?: ActiveLocalizedUrlArray;
  earlyVoteInformationalRichText?: ActiveLocalizedUrlArray;
  electionDayInformationalRichText?: ActiveLocalizedUrlArray;
};

export type RichTextUrlTypes =
  | 'vepRegRichText'
  | 'vepIdRichText'
  | 'vepCompleteBallotRichText'
  | 'vepVBMRichText'
  | 'alertRichText'
  | 'locateRichText'
  | 'absenteeExcuseRichText'
  | 'dropoffInformationalRichText'
  | 'earlyVoteInformationalRichText'
  | 'electionDayInformationalRichText';

export function isRichTextUrlType(n: any): n is RichTextUrlTypes {
  return n.includes('RichText');
}

export type PlainTextUrlTypes =
  | 'onlineReg'
  | 'alternateReg'
  | 'mailInReg'
  | 'inPersonReg'
  | 'ballotRequest'
  | 'locationLookup';

export function isPlainTextUrlType(n: any): n is PlainTextUrlTypes {
  return !n.includes('RichText');
}

export type LocalizedRichTextUrlsChecked = {
  [key in RichTextUrlTypes]: ActiveLocalizedBoolean;
};

export type LocalizedPlainTextUrlsChecked = {
  [key in PlainTextUrlTypes]: ActiveLocalizedBoolean;
};

export type TrackExternalUrlsChecked = LocalizedRichTextUrlsChecked &
  LocalizedPlainTextUrlsChecked;

/**
 * Currently URLs are only localized for locales in {@link DefaultLocale}.
 * If/When URLs are localized to include locales in {@link CustomLocale},
 * this constant will need to be updated.
 */
export const EXTERNAL_URLS_CHECKED_INITIAL_STATE: TrackExternalUrlsChecked = {
  vepRegRichText: { en: false, es: false },
  vepIdRichText: { en: false, es: false },
  vepVBMRichText: { en: false, es: false },
  vepCompleteBallotRichText: { en: false, es: false },
  locateRichText: { en: false, es: false },
  alertRichText: { en: false, es: false },
  absenteeExcuseRichText: { en: false, es: false },
  onlineReg: { en: false, es: false },
  mailInReg: { en: false, es: false },
  inPersonReg: { en: false, es: false },
  alternateReg: { en: false, es: false },
  ballotRequest: { en: false, es: false },
  locationLookup: { en: false, es: false },
  dropoffInformationalRichText: { en: false, es: false },
  earlyVoteInformationalRichText: { en: false, es: false },
  electionDayInformationalRichText: { en: false, es: false },
};

// END JURISDICTION: URL TYPES

// BEGIN JURISDICTION: BOOLEAN TYPES
export type NationalBooleanConfig = {
  [key in State]: JurisdictionBooleanConfig;
};

export type NationalLocalesConfig = {
  [key in State]: JurisdictionLocalesConfig;
};

export type LandingPageBooleans = {
  vepEnabled: boolean;
  displayVotingLocationLookup: boolean;
  hasActiveElection: boolean;
  displayRegistrationLookup: boolean;
  ballotRequestFlowEnabled: boolean;
  hasActiveAlerts: boolean;
};

export type VepBooleans = {
  displayDatesDeadlines: boolean;
  earlyVotingStartIsSameStatewide: boolean;
  includeLocateOnVep: boolean;
  includeLocateInVepDates: boolean;
  displayIdCopy: boolean;
  displayRegistrationCopy: boolean;
  displayHowToCompleteBallotCopy: boolean;
  enableCustomSection: boolean;
};

export type LocationFinderAudit = {
  displayVotingLocationLookup: boolean;
  displayEarlyAbsenteeExcuse: boolean;
  isEarlyVotingAllowed: boolean;
  informationalNotes: {
    dropoffLocations: boolean;
    earlyVoteLocations: boolean;
    electionDayLocations: boolean;
  };
  hasActiveElection: boolean;
  hasLocateOverrideMessage: boolean;
  lookupExperience: LocationLookupExperienceTypes;
  sosLookupUrl: ActiveLocalizedUrl | null;
};

export type BallotRequestBooleans = {
  hasUniversalVbm: boolean;
  ballotRequestFlowEnabled: boolean;
};

export type RegistrationBooleans = {
  registrationFlowEnabled: boolean;
  onlineRegistrationEnabled: boolean;
  onlineRegistrationDeadlineIsDisplayed: boolean;
  mailRegistrationEnabled: boolean;
  mailRegistrationDeadlineIsDisplayed: boolean;
  showRegistrationSteps: boolean;
  inPersonRegistrationEnabled: boolean;
  inPersonRegistrationDeadlineIsDisplayed: boolean;
  sameDayRegistrationEnabled: boolean;
};

export type JurisdictionBooleanConfig = {
  landingPage: LandingPageBooleans;
  vep: VepBooleans;
  locate: LocationFinderAudit;
  ballotRequest: BallotRequestBooleans;
  register: RegistrationBooleans;
};

export type JurisdictionLocalesConfig = {
  supportedLocales: JurisdictionLocaleSupport;
  priorityLocales: JurisdictionLocaleSupport;
};
//END JURISDICTION: BOOLEAN TYPES

// END JURISDICTION MODELS IN IWV

// MODELS FROM CONTENTFUL

export type ContentfulJurisdictionConfig = {
  stateName: { en: string };
  stateCode: { en: string };
  voterHotline?: { en: string };
  languageSupport?: { en: Array<string> };
  priorityLanguages?: { en: Array<string> };
  displayDatesDeadlines: { en: boolean };
  earlyVotingStartIsSameStatewide: { en: boolean };
  importantDatesAndDeadlines?: {
    en: Array<ContentfulLinkedDate | ContentfulLinkedVbmNote>;
  };
  vepConfig: { en: ContentfulLinkedVEPConfig };
  pollingLocationLookupConfig: { en: ContentfulLinkedPollingLookupConfig };
  voterRegConfig: { en: ContentfulLinkedVoterRegConfig };
  jurisdictionAlertsConfig?: { en: ContentfulLinkedAlert };
  activeElection?: { en: ContentfulLinkedElection };
  ballotRequestConfig?: { en: ContentfulLinkedBallotRequestConfig };
  hotlineAndFooterOverrideConfig?: {
    en: ContentfulLinkedHotlineFooterOverride;
  };
};

export type ContentfulTerritoryConfig = {
  territoryName: { en: string };
  territoryCode: { en: string };
  displayDatesDeadlines: { en: boolean };
  importantDatesAndDeadlines?: { en: Array<ContentfulLinkedDate> };
  vepConfig: { en: ContentfulTerritoryVEPConfig };
};

export type ContentfulSitewideAlertsObject = {
  alertMessage: ContentfulRichTextObject;
  internalTitle: { en: string };
  startDate: ContentfulDate;
  endDate: ContentfulDate;
  stateCode: { en: 'Sitewide' };
};

// BEGIN RICHTEXT TYPES

export type ContentfulRichTextObject = ActiveLocalizedRichTextObj;

export type ContentfulUrl = {
  nodeType: INLINES.HYPERLINK;
  data: { uri: string };
  content: Array<any>;
};

interface HasNodeType {
  nodeType: string;
}

function hasNodeType<T extends { nodeType?: string }>(
  obj: T
): obj is T & HasNodeType {
  return typeof obj.nodeType === 'string';
}

export function isContentfulUrl(n: unknown): n is ContentfulUrl {
  if (!n || !hasNodeType(n)) {
    return false;
  }
  return n.nodeType === INLINES.HYPERLINK;
}
// END RICHTEXT TYPES

// BEGIN DATE TYPES
export type ContentfulDate = {
  en: string;
};

export type ContentfulDates = {
  earlyVotingStartBy?: ContentfulLinkedDateFields;
  electionDay?: ContentfulLinkedDateFields;
  electionDayStatewideHours?: ContentfulLinkedDateFields;
  inPersonAbsenteeStartBy?: ContentfulLinkedDateFields;
  requestBallotBy?: ContentfulLinkedDateFields;
  dropoffBallotBy?: ContentfulLinkedDateFields;
  postmarkBallotBy?: ContentfulLinkedDateFields;
  receiveBallotBy?: ContentfulLinkedDateFields;
  registerOnlineBy?: ContentfulLinkedDateFields;
  registerInPersonBy?: ContentfulLinkedDateFields;
  registerSameDay?: ContentfulLinkedDateFields;
  registerReceiveBy?: ContentfulLinkedDateFields;
  registerPostmarkBy?: ContentfulLinkedDateFields;
  voteByMailNote?: ContentfulRichTextObject;
};

export type ContentfulDateMappingType = {
  [key in ImportantDateType]: string;
};

export type ContentfulLinkedVbmNote = {
  metadata: Metadata;
  sys: EntrySys;
  fields: ContentfulLinkedVbmNoteFields | undefined;
};

export type ContentfulLinkedVbmNoteFields = {
  internalTitle: { en: string };
  noteCopy: ContentfulRichTextObject;
};

export type ContentfulLinkedDate = {
  metadata: Metadata;
  sys: EntrySys;
  fields: ContentfulLinkedDateFields | undefined;
};

export type ContentfulLinkedDateFields = {
  note?: ActiveLocalizedString;
  longNote?: ContentfulRichTextObject;
  dateTitle: { en: string };
  dateType: { en: ImportantDateType };
  electionDate?: ContentfulDate;
};

interface HasFields {
  fields: object;
}

function hasFields<T extends { fields?: object }>(
  obj: T
): obj is T & HasFields {
  return typeof obj.fields === 'object';
}

export function isLinkedDate(n: unknown): n is ContentfulLinkedDate {
  // dateType and dateTitle exist on ContentfulLinkedDate
  // but do not exist on ContentfulLinkedVbmNote
  // this method is used to identify ContentfulLinkedDates
  // in an array that _may_ have a ContentfulLinkedVbmNote
  // If the object does not have fields, return false
  if (!n || !hasFields(n)) {
    return false;
  }
  const hasDateTypeField = 'dateType' in n.fields;
  const hasDateTitleField = 'dateTitle' in n.fields;
  return hasDateTypeField && hasDateTitleField;
}

export type ImportantDateType =
  | 'Early Vote Start By'
  | 'Election Day'
  | 'Election Day Statewide Hours'
  | 'In Person Absentee Start By'
  | 'Request Ballot By'
  | 'Dropoff Ballot By'
  | 'Postmark Ballot By'
  | 'Receive Ballot By'
  | 'Register Online By'
  | 'Register In Person By'
  | 'Register Same Day'
  | 'Receive Register By'
  | 'Register Postmark By'
  | 'Vote by Mail Note';

// END DATE TYPES

// BEGIN ALERT TYPES
export type ContentfulLinkedAlert = {
  metadata: Metadata;
  sys: EntrySys;
  fields: LinkedAlertFields;
};

export type LinkedAlertFields = {
  alertMessage: ContentfulRichTextObject;
  internalTitle: { en: string };
  startDate: ContentfulDate;
  endDate: ContentfulDate;
};

export type TestingAlertFields = {
  alertMessage: ContentfulRichTextObject;
  internalTitle: { en: string };
  startDate: { en: moment.Moment };
  endDate: { en: moment.Moment };
};
// END ALERT TYPES

// BEGIN ELECTION TYPES
export type ContentfulLinkedElection = {
  metadata?: Metadata;
  sys: EntrySys;
  fields?: LinkedActiveElectionFields;
};

export type LinkedActiveElectionFields = {
  internalName: { en: string };
  stateCode: { en: string };
  electionDate: ContentfulDate;
  electionType: { en: ActiveElectionTypes };
  isEarlyVotingAllowed: { en: boolean };
  earlyVotingStartDate?: ContentfulDate;
  earlyVotingEndDate?: ContentfulDate;
};

const ACTIVE_ELECTION_TYPES = [
  'caucus',
  'general',
  'primary',
  'special',
] as const;
export type ActiveElectionTypes = (typeof ACTIVE_ELECTION_TYPES)[number];

export function isActiveElectionType(n: string): n is ActiveElectionTypes {
  return ACTIVE_ELECTION_TYPES.includes(n as ActiveElectionTypes);
}

// END ELECTION TYPES

// BEGIN LOOKUP TYPES
export type ContentfulLinkedPollingLookupConfig = {
  metadata: Metadata;
  sys: EntrySys;
  fields: LinkedPollingLookupConfigFields;
};

export type ContentfulLocationLookupExperienceTypes =
  | 'SOS website'
  | 'IWV Locate tool';

export type LinkedPollingLookupConfigFields = {
  stateCode: { en: string };
  displayVotingLocationLookup: { en: boolean };
  earlyVoteInformationalNote?: ContentfulRichTextObject;
  dropoffInformationalNote?: ContentfulRichTextObject;
  electionDayInformationalNote?: ContentfulRichTextObject;
  displayEarlyAbsenteeExcuse: { en: boolean };
  earlyAbsenteeExcuseCopy?: ContentfulRichTextObject;
  locationLookupExperience: { en: ContentfulLocationLookupExperienceTypes };
  sosLocationLookupUrl?: ActiveLocalizedUrl;
  locateErrorStartDate?: ContentfulDate;
  locateErrorEndDate?: ContentfulDate;
  locateErrorHeadlineCopy?: ActiveLocalizedString;
  locateErrorMessageCopy?: ContentfulRichTextObject;
};
// END LOOKUP TYPES

// BEGIN REGISTER TYPES

// new model
export type ContentfulLinkedVoterRegConfig = {
  metadata: Metadata;
  sys: EntrySys;
  fields: LinkedVoterRegConfigFields;
};

// new model
export type LinkedVoterRegConfigFields = {
  stateCode: { en: string };
  displayRegistrationLookup: { en: boolean };
  onlineRegistrationEnabled: { en: boolean };
  mailRegistrationEnabled: { en: boolean };
  showRegistrationSteps: { en: boolean };
  sameDayRegistrationEnabled: { en: boolean };
  sameDayRegistrationHeading: ActiveLocalizedString;
  sameDayRegistrationCopy?: ContentfulRichTextObject;
  onlineRegistrationCopy?: ContentfulRichTextObject;
  onlineRegistrationUrl?: ActiveLocalizedUrl;
  onlineRegistrationButtonText?: ActiveLocalizedString;
  onlineRegistrationHeading: ActiveLocalizedString;
  onlineRegistrationDeadlineIsDisplayed: { en: boolean };
  alternateRegistrationUrl?: ActiveLocalizedUrl;
  alternateRegistrationButtonText?: ActiveLocalizedString;
  mailRegistrationCopy?: ContentfulRichTextObject;
  mailRegistrationUrl?: ActiveLocalizedUrl;
  mailRegistrationButtonText?: ActiveLocalizedString;
  mailRegistrationHeading: ActiveLocalizedString;
  mailRegistrationDeadlineIsDisplayed: { en: boolean };
  inPersonRegistrationEnabled: { en: boolean };
  inPersonRegistrationCopy?: ContentfulRichTextObject;
  inPersonRegistrationUrl?: ActiveLocalizedUrl;
  inPersonRegistrationButtonText?: ActiveLocalizedString;
  inPersonRegistrationHeading: ActiveLocalizedString;
  inPersonRegistrationDeadlineIsDisplayed: { en: boolean };
};
// END REGISTER TYPES

// BEGIN VEP TYPES
export type ContentfulLinkedVEPConfig = {
  metadata: Metadata;
  sys: EntrySys;
  fields: LinkedVepConfigFields;
};

export type LinkedVepConfigFields = {
  stateCode: { en: string };
  vepEnabled: { en: boolean };
  includeLocateOnVep: { en: boolean };
  includeLocateInVepDates: { en: boolean };
  displayIdCopy: { en: boolean };
  idRequirementsCopy?: ContentfulRichTextObject;
  displayRegistrationCopy: { en: boolean };
  registrationRequirementsCopy?: ContentfulRichTextObject;
  displayHowToCompleteBallotCopy: { en: boolean };
  howToCompleteBallotCopy?: ContentfulRichTextObject;
  enableCustomSectionOnVep: { en: boolean };
  customSectionHeading?: ActiveLocalizedString;
  customSectionCopy?: ContentfulRichTextObject;
};

export type ContentfulTerritoryVEPConfig = {
  metadata: Metadata;
  sys: EntrySys;
  fields: TerritoryVepConfigFields;
};

export type TerritoryVepConfigFields = {
  territoryCode: { en: string };
  vepEnabled: { en: boolean };
  displayIdCopy: { en: boolean };
  idRequirementsCopy?: ContentfulRichTextObject;
  displayRegistrationCopy: { en: boolean };
  registrationRequirementsCopy?: ContentfulRichTextObject;
};
// END VEP TYPES

// BEGIN VBM/ABSENTEE TYPES
export type ContentfulLinkedBallotRequestConfig = {
  metadata: Metadata;
  sys: EntrySys;
  fields: LinkedBallotRequestFields;
};

export type ContentfulBallotRequestExperienceTypes =
  | 'SOS website'
  | 'contact capture form';

export type LinkedBallotRequestFields = {
  stateCode: { en: string };
  hasUniversalVbm: { en: boolean };
  ballotRequestFlowEnabled: { en: boolean };
  ballotRequestExperience: { en: ContentfulBallotRequestExperienceTypes };
  sosBallotRequestUrl: ActiveLocalizedUrl;
  ballotRequestButtonText: ActiveLocalizedString;
};
// END VBM TYPES

// START HOTLINE/FOOTER OVERRIDE TYPES
export type ContentfulLinkedHotlineFooterOverride = {
  metadata: Metadata;
  sys: EntrySys;
  fields: LinkedHotlineFooterOverrideFields;
};

export type LinkedHotlineFooterOverrideFields = {
  hotlineOverrideCopy: ContentfulRichTextObject;
  footerOverrideCopy: ContentfulRichTextObject;
  stateCode: { en: string };
};
// END HOTLINE/FOOTER OVERRIDE TYPES
