import { Location } from 'react-router-dom';

import { parseQueryString } from '../../services/utils';
import { Jurisdiction } from '../data';
import { ActiveLocale, Option } from '../utils';

import { GoogleAnalyticsEvent } from './analytics-event';
import { UTMFields } from './form-service';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export interface AnalyticsAdapter {
  setUserId(userId: string): void;

  setJurisdiction(jurisdiction: Option<Jurisdiction>): void;
  setLocale(locale: ActiveLocale): void;

  routeChanged(
    location: Location,
    locale: ActiveLocale,
    jurisdiction: Option<Jurisdiction>
  ): void;
  recordEvent(event: GoogleAnalyticsEvent): Promise<void>;
  recordConversion(to: string): void;
}

type EventOptions = {
  category: string;
  action: string;
  label?: string;
};

type ClickedEventOptions = {
  category: string;
  target: string;
  isExternal: boolean;
};

type TimedEventOptions = {
  category: string;
  label: string;
  value: number;
  variable: string;
};

/**
 * Service to send web analytics to a provider of our choice.
 *
 * Uses the adapter pattern so that it can have helper methods (like
 * {@link #clicked}) that don’t have to be re-implemented in each adaptor. But
 * overall doesn’t need to be too general because we really only have one place
 * we send analytics.
 */
export class AnalyticsService {
  utmFields: UTMFields = {
    partnerCampaign: '',
    partnerContent: '',
    partnerCustom: '',
    partnerId: '',
    partnerMedium: '',
    partnerTerm: '',
  };

  constructor(private adapter: AnalyticsAdapter) {}

  public setLocale(locale: ActiveLocale) {
    this.adapter.setLocale(locale);
  }

  public setJurisdiction(jurisdiction: Option<Jurisdiction>) {
    this.adapter.setJurisdiction(jurisdiction);
  }

  public setUserId(userId: string) {
    this.adapter.setUserId(userId);
  }

  public setUtmFields(utmFields: UTMFields) {
    this.utmFields = utmFields;
  }

  public routeChanged(
    location: Location,
    locale: ActiveLocale,
    jurisdiction: Option<Jurisdiction>
  ) {
    this.adapter.routeChanged(location, locale, jurisdiction);
  }

  public event({ category, action, label }: EventOptions): Promise<void> {
    return this.adapter.recordEvent({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      ...(label ? { eventLabel: label } : {}),
    });
  }

  public recordConversion(type: 'ActionOptionsClick' | 'LocationSearchSubmit') {
    if (type === 'ActionOptionsClick' || type === 'LocationSearchSubmit') {
      // 2024 DCS for HFP
      this.adapter.recordConversion('AW-16725117467/nQuyCJSY59kZEJuUlKc-');
    }
  }

  public clicked({
    category,
    target,
    isExternal,
  }: ClickedEventOptions): Promise<void> {
    if (isExternal) {
      return this.adapter.recordEvent({
        hitType: 'event',
        eventCategory: 'Outbound Link',
        eventAction: 'click',
        eventLabel: target,
      });
    } else {
      return this.adapter.recordEvent({
        hitType: 'event',
        eventCategory: category,
        eventAction: 'click',
        eventLabel: target,
      });
    }
  }

  public timed({
    category,
    label,
    value,
    variable,
  }: TimedEventOptions): Promise<void> {
    return this.adapter.recordEvent({
      hitType: 'timing',
      timingCategory: category,
      timingLabel: label,
      timingValue: value,
      timingVar: variable,
    });
  }
}

/**
 * Implementation for tests that just swallows the events and doesn’t do
 * anything with them.
 *
 * We include the parameters so that subclasses can override this with
 * partial implementations.
 */
export class NullAnalyticsAdapter implements AnalyticsAdapter {
  setUserId(_: string) {}

  setJurisdiction(_: Option<Jurisdiction>) {}
  setLocale(_: ActiveLocale) {}

  routeChanged(_: Location) {}

  recordEvent(_: GoogleAnalyticsEvent) {
    return Promise.resolve();
  }

  recordConversion(_: string): void {}
}

/**
 * Default adaptor that sends information to Google Analytics.
 */
export class GoogleAnalyticsAdapter implements AnalyticsAdapter {
  public setJurisdiction(jurisdiction: Option<Jurisdiction>): void {
    const analyticsJurisdiction = jurisdiction ?? '[unknown]';
    gtag('set', 'jurisdiction', analyticsJurisdiction);
  }

  public setLocale(locale: ActiveLocale): void {
    gtag('set', 'lang', locale);
  }

  public routeChanged(
    location: Location<unknown>,
    locale: ActiveLocale,
    jurisdiction: Option<Jurisdiction>
  ) {
    const params = parseQueryString(location.search);

    // We set these dynamically in case any of our redirects has added them to
    // the URL. (For example, short URLs that we print in mailers.)
    //
    // See:
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#campaignSource
    if (params['utm_source']) {
      gtag('set', { campaign_source: params['utm_source'] });
    }
    if (params['utm_medium']) {
      gtag('set', { campaign_medium: params['utm_medium'] });
    }
    if (params['utm_campaign']) {
      gtag('set', { campaign_name: params['utm_campaign'] });
    }
    if (params['utm_term']) {
      gtag('set', { campaign_term: params['utm_term'] });
    }
    if (params['utm_content']) {
      gtag('set', { campaign_content: params['utm_content'] });
    }

    // We explicitly just send the pathname as the page to remove any query
    // parameters, which are not interesting for analytics and/or have PII.
    gtag('event', 'page_view', {
      page_location: location.pathname,
      jurisdiction: jurisdiction ?? '[unknown]',
      lang: locale,
      // specifically send this to "analytics"
      // instead of "adwords"
      send_to: 'analytics',
    });
  }

  public setUserId(_userId: string) {
    // Not currently supported for GA.
    //
    // Used in our experiment framework, which is not currently doing anything.
  }

  public recordEvent(event: GoogleAnalyticsEvent): Promise<void> {
    return new Promise((resolve, reject) => {
      gtag('event', 'record_event', {
        ...event,
        hitCallback: resolve,
        hitCallbackFail: reject,
        // specifically send this to "analytics"
        // instead of "adwords"
        send_to: 'analytics',
      });
    });
  }

  public recordConversion(to: string): void {
    gtag('event', 'conversion', { send_to: to });
  }
}
